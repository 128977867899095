import readXlsxFile from 'read-excel-file';
import { BULK_UPLOAD_VEHICLE_SCHEMA } from '@/config/bulkTableSchema';
import notify from '@/utils/notify';
import Vehicle from '@/models/vehicleCapture/Vehicle';
import rest from '@/plugins/rest';
import _cloneDeep from 'lodash/cloneDeep';

export default {
  async bulkVehicleUploadTemplate(context, payload) {
    const schema = BULK_UPLOAD_VEHICLE_SCHEMA;
    let vehicles = [];
    await readXlsxFile(payload.files[0], { schema }).then(({ rows, errors }) => {
      const newArray = rows.map(element => {
        const vehicle = new Vehicle(element);

        return vehicle;
      });

      if (errors.length > 0 || rows.length === 0) {
        notify.error('An error occured while uploading template.');
        return;
      }
      vehicles = newArray;
    });
    return vehicles;
  },
  async getVehicleTemplate(context, payload) {
    try {
      const { data } = await rest.get(`/vehicles/${payload.vehicleNumber}/vehicle-indicators?clientId=${payload.clientId}`);

      return data;
    } catch (error) {
      notify.error('An error occured while loading the vehicle template.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Vehicle Template');

      return Promise.resolve();
    }
  },

  addTransactionItem(context, payload) {
    const transactionItem = _cloneDeep(payload);
    let vehicles = [];
    vehicles.push(JSON.parse(JSON.stringify(transactionItem.item)));

    if (transactionItem.vehicleCapture.vehicleCapture.vehicles.length > 0) {
      vehicles = [...transactionItem.vehicleCapture.vehicleCapture.vehicles, ...vehicles];
    }
    return vehicles;
  },

  editTransactionItem(context, payload) {
    const transactionItem = _cloneDeep(payload);
    Object.assign(transactionItem.vehicleCapture.vehicleCapture.vehicles[transactionItem.selectedItemIndex], transactionItem.item);
    return transactionItem.vehicleCapture.vehicleCapture.vehicles;
  },

  removeTransationItem(context, payload) {
    const transactionItem = _cloneDeep(payload);
    transactionItem.vehicleCapture.vehicles.splice(transactionItem.selectedItemIndex, 1);
    return transactionItem.vehicleCapture.vehicles;
  },
};
