import moment from 'moment';
import notify from '@/utils/notify';
import rest from '@/plugins/rest';
import { readFileBase64, base64toBlob } from '@/utils';
import Attachment from '@/models/Attachment';
import { EventBus } from '@/utils/event-bus';

export default {

  async getAttachment(context, attachmentId) {
    try {
      const { data } = await rest.get(`/documents/${attachmentId}`);
      const attachment = new Attachment(data);

      const blob = base64toBlob(attachment.base64Content);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = attachment.filename;
      link.click();

      return Promise.resolve();
    } catch (error) {
      notify.error('An error occured while loading Documents.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Documents');

      return Promise.resolve();
    }
  },

  async getAllAttachments(context, payload) {
    try {
      const { serviceRequestId, fileType } = payload;
      let url = '';
      if (fileType === undefined) {
        url = `/documents/all/${serviceRequestId}?fileType=`;
      } else {
        url = `/documents/all/${serviceRequestId}?fileType=${fileType}`;
      }

      const { data } = await rest.get(url);

      return Promise.resolve(data);
    } catch (error) {
      if (error.response.status === 400) {
        return Promise.resolve({ status: error.response.status });
      }
      notify.error('An error occured while loading Documents.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Documents');

      return Promise.resolve();
    }
  },

  async getAttachmentAuthLetter(context, payload) {
    try {
      const { serviceRequestId } = payload;
      const { data } = await rest.get(`/documents/auth-letter/${serviceRequestId}`);

      return new Attachment(data.documents[0]);
    } catch (error) {
      if (error.response.status === 400) {
        notify.error(error.response.statusText);
        return Promise.resolve({ status: error.response.status });
      }
      notify.error('An error occured while auth letter Documents.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Documents');

      return Promise.resolve();
    }
  },

  async uploadAttachments(context, payload) {
    try {
      const { files, fileType, serviceRequestId } = payload;
      const attachmentsResponse = [];
      const filePromises = [];
      let filename;
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        // eslint-disable-next-line
        const base64Data = await readFileBase64(file);
        filename = file.name;
        const attachment = new Attachment({
          base64Content: base64Data,
          contentType: file.type,
          filename: file.name,
          lastModifiedDate: moment(file.lastModified).format('DD-MM-YYYY HH:mm:ss'),
          contentLength: file.size,
          fileType,
          id: '',
          serviceRequestId,
        });

        const postOptions = {
          timeout: 500000,
        };

        filePromises.push(rest.post('/documents', attachment, postOptions).then());
      }

      const responses = await Promise.allSettled(filePromises);
      if (responses.find((x) => x.status === 'rejected')) {
        notify.error(`Unable to upload ${filename.split('.').shift()} Please try again.`);
      } else {
        responses.forEach(promiseResponse => {
          const response = promiseResponse.value;
          const { data } = response;

          if (response.status === 201) {
            attachmentsResponse.push(new Attachment(data));
          }
        });
        notify.success(`${filename.split('.').shift()} Uploaded Successfully`);
        EventBus.$emit('upload-completed');
      }

      return Promise.resolve(attachmentsResponse);
    } catch (error) {
      notify.error('Failed to Uplaod Document(s). Please try again later.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Upload Documents');

      return Promise.resolve([]);
    }
  },
};
