import axios from 'axios';
import _groupBy from 'lodash/groupBy';

import notify from '@/utils/notify';
import rest from '@/plugins/rest';

import ErrorEvent from '@/models/ErrorEvent';
import RemittanceAdvice from '@/models/RemittanceAdvice';

export default {
  async searchRemittanceAdvice({ commit }, payload) {
    try {
      const remittanceAdvicesGroupedArray = [];
      commit('remittanceAdvices', remittanceAdvicesGroupedArray);

      const { merchantId, dateRange } = payload;
      const dateFrom = dateRange[0];
      const dateTo = dateRange[1];

      let { data } = await rest.get(`/merchants/${merchantId}/documents/remittance-advice?dateFrom=${dateFrom}&dateTo=${dateTo}&generatePdf=false`);
      if (data.constructor.name === 'Object') {
        data = [];
      }

      if (data.length > 0) {
        const remittanceAdvices = data.map((x) => new RemittanceAdvice(x));
        const remittanceAdvicesGrouped = _groupBy(remittanceAdvices, 'paymentDate');
        Object.keys(remittanceAdvicesGrouped).forEach((key) => {
          const remittanceAdviceItem = {
            paymentDate: key,
            paymentTotal: remittanceAdvicesGrouped[key][0].paymentTotal,
            merchantBank: remittanceAdvicesGrouped[key][0].bankAccount,
            items: remittanceAdvicesGrouped[key],
          };

          remittanceAdvicesGroupedArray.push(remittanceAdviceItem);
        });
      }
      commit('remittanceAdvices', remittanceAdvicesGroupedArray);
      return remittanceAdvicesGroupedArray;
    } catch (error) {
      if (axios.isCancel(error)) {
        return null;
      }

      notify.error('An error occured while searching for Remittance Advice. Please try again later.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Search Remittance Advice');

      return Promise.resolve();
    }
  },

  async downloadRemittanceAdvice(context, payload) {
    try {
      const { merchantId, dateRange } = payload;
      const dateFrom = dateRange[0];
      const dateTo = dateRange[1];

      const { data } = await rest.get(`/merchants/${merchantId}/documents/remittance-advice?dateFrom=${dateFrom}&dateTo=${dateTo}&generatePdf=true`);

      const remittanceAdvice = new RemittanceAdvice(data[0]);
      return {
        fileContentType: remittanceAdvice.fileContentType,
        fileData: remittanceAdvice.fileData,
        filename: remittanceAdvice.filename,
      };
    } catch (error) {
      if (axios.isCancel(error)) {
        return null;
      }

      notify.error('An error occured while searching for Remittance Advice. Please try again later.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Search Remittance Advice');

      return Promise.resolve();
    }
  },
};
