import axios from 'axios';
import notify from '@/utils/notify';
import rest from '@/plugins/rest';

import Merchant from '../../../models/Merchant';
import CommissionCharge from '../../../models/CommissionCharge';
import ReferenceList from '../../../models/ReferenceList';

let cancel;
const { CancelToken } = axios;

export default {
  async searchMerchants({ commit }, searchString) {
    try {
      if (cancel !== undefined) {
        cancel();
      }

      const { data } = await rest.post('/merchants/search', {
        merchantId: searchString.toUpperCase(),
      },
      {
        cancelToken: new CancelToken(((c) => {
          cancel = c;
        })),
      });

      commit('merchantResults', data);
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return null;
      }

      notify.error('An error occured while searching for Merchants. Please try again later.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Search Merchants');

      return Promise.resolve();
    }
  },

  async getMerchantDetails({ commit }, merchantId) {
    try {
      const { data } = await rest.get(`/merchants/${merchantId}`);
      const merchantData = new Merchant(data);

      commit('merchantDetails', merchantData);
      return merchantData;
    } catch (error) {
      notify.error('An error occured while loading the Merchant Details.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Merchant Details');

      return Promise.resolve();
    }
  },

  async getMerchantServiceOfferings(contenxt, merchantId) {
    try {
      const { data } = await rest.get(`/merchants/${merchantId}/service-offerings`);

      if (data.length === 0) {
        return [];
      }

      return data.map((x) => new ReferenceList(x));
    } catch (error) {
      notify.error('An error occured while loading the Merchant Service Offerings.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Merchant Service Offerings');

      return Promise.resolve();
    }
  },

  async getMerchantCommissionCharges({ commit }, merchantId) {
    try {
      const { data } = await rest.get(`/merchants/${merchantId}/commission-charges`);
      const commissionCharges = new CommissionCharge(data[0]);

      commit('commissionCharges', commissionCharges);
      return commissionCharges;
    } catch (error) {
      notify.error('An error occured while loading the latest Merchant Commission Charges.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Merchant Commission Charges');

      return Promise.resolve();
    }
  },

  async searchJobCardItems(context, payload) {
    try {
      const { merchantId, itemType, searchString } = payload;

      if (cancel !== undefined) {
        cancel();
      }

      let { data } = await rest.get(`/merchants/${merchantId}/catalogue/${itemType}/items?searchString=${searchString}`,
        {
          cancelToken: new CancelToken(((c) => {
            cancel = c;
          })),
        });

      if (data.constructor.name === 'Object') {
        data = [{ id: '' }];
      }

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return [];
      }

      notify.error('Error while searhing Job Card Items');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Search Job Card Items');

      return Promise.resolve();
    }
  },
};
