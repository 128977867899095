export default {
  user(currentState, value) {
    const state = currentState;
    state.user = value;
  },

  tempUser(currentState, value) {
    const state = currentState;
    state.tempUser = value;
  },

  displayAuthorisationResponseDialog(currentState, value) {
    const state = currentState;
    state.displayAuthorisationResponseDialog = value;
  },

  displayDeclineInvoiceReasonDialog(currentState, value) {
    const state = currentState;
    state.displayDeclineInvoiceReasonDialog = value;
  },

  verifier(currentState, value) {
    const state = currentState;
    state.verifier = value;
  },

};
