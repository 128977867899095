import notify from '@/utils/notify';
import rest from '@/plugins/rest';

export default {

  async getCardTypes({ commit }, clientId) {
    try {
      const { data } = await rest.get(`/clients/${clientId}/card-types`);
      commit('cardTypesResults', data);
      return data;
    } catch (error) {
      notify.error('An error occured while loading the card types Details.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get card types Details');

      return Promise.resolve();
    }
  },
};
