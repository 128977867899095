import notify from '@/utils/notify';
import rest from '@/plugins/rest';
import axios from 'axios';

let cancel;
const { CancelToken } = axios;
export default {

  async getValueProductCodes(context, payload) {
    try {
      const { clientId, searchValue } = payload;

      if (cancel !== undefined) {
        cancel();
      }

      let { data } = await rest.get(`/clients/${clientId}/value-addcode?searchValue=${searchValue}`,
        {
          cancelToken: new CancelToken(((c) => {
            cancel = c;
          })),
        });

      if (data.constructor.name === 'Array') {
        data = [];
      }

      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return [];
      }

      notify.error('Error while searhing Value add products ');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Search JValue add products');

      return Promise.resolve();
    }
  },

};
