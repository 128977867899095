import axios from 'axios';
import notify from '@/utils/notify';
import rest from '@/plugins/rest';

let cancel;
const { CancelToken } = axios;

export default {
  async searchClients({ commit }, searchString) {
    try {
      if (cancel !== undefined) {
        cancel();
      }

      const { data } = await rest.post('/clients/search', {
        clientId: searchString,
      },
      {
        cancelToken: new CancelToken(((c) => {
          cancel = c;
        })),
      });

      commit('clientResults', data);
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return null;
      }

      notify.error('An error occured while searching for Clients. Please try again later.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Search Clients');

      return Promise.resolve();
    }
  },
  async getClientVehicleFunds(context, payload) {
    try {
      if (cancel !== undefined) {
        cancel();
      }
      const { requestType, clientNumber, billingCentre, fundNumber, vehicleNumber } = payload;
      const requestTypeParam = `requestType=${requestType}`;
      const clientNumberParam = `clientNumber=${clientNumber}`;
      const billingCentreParam = `billingCentre=${billingCentre}`;
      const fundNumberParam = `fundNumber=${fundNumber}`;
      const vehicleNumberParam = `vehicleNumber=${vehicleNumber}`;

      const url = `/funds?${requestTypeParam}&${clientNumberParam}&${billingCentreParam}&${fundNumberParam}&${vehicleNumberParam}`;
      const { data } = await rest.get(url, {
        cancelToken: new CancelToken(((c) => {
          cancel = c;
        })),
      });
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return null;
      }

      notify.error('An error occured while loading funds.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Clients Vehicle Funds');

      return Promise.resolve();
    }
  },

  async getVehicleIndicators(context, clientId) {
    try {
      const { data } = await rest.get(`/clients/${clientId}/vehicle-indicators`);
      return data;
    } catch (error) {
      notify.error('An error occured while loading the Vehicle Indicators.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Getting Vehicle Indicators');

      return Promise.resolve();
    }
  },
  async getValueAddCode() {
    try {
      const { data } = await rest.get('/clients/value-addcode');
      return data;
    } catch (error) {
      notify.error('An error occured while loading the products.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Getting products');

      return Promise.resolve();
    }
  },

  async getClieintUninvolvedDetails(context, clientId) {
    try {
      const { data } = await rest.get(`/clients/${clientId}/uninvolved`);
      return data;
    } catch (error) {
      notify.error('An error occured while loading the products.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Getting products');

      return Promise.resolve();
    }
  },

};
