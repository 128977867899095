import ServiceRequest from '@/models/ServiceRequest';
import Task from '@/models/Task';

export default {
  serviceRequest: new ServiceRequest(),
  serviceRequests: [],
  serviceRequestTask: new Task(),
  serviceRequestTasks: [],
  showServiceRequestTasks: false,
};
