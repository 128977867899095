import notify from '@/utils/notify';
import rest from '@/plugins/rest';

import Event from '@/models/Event';

export default {
  async updateEvent(context, event) {
    try {
      const { id } = event;

      await rest.patch(`/events/${id}`, event);

      notify.success('Successfully updated event');
      return Promise.resolve();
    } catch (error) {
      notify.error('Failed to Update Event. Please try again later.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Update Event');

      return Promise.resolve();
    }
  },

  async getEvents({ commit }, payload) {
    try {
      const { referenceId, type } = payload;
      commit('events', []);

      const { data } = await rest.get(`/events?referenceType=${type}&referenceId=${referenceId}`);

      if (data.length === 0) {
        commit('events', []);
        return [];
      }

      const events = data.map((x) => new Event(x));

      commit('events', events);
      return events;
    } catch (error) {
      notify.error('An error occured while loading Events.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Events');

      return Promise.resolve();
    }
  },

  async getEvent({ commit }, eventId) {
    try {
      const { data } = await rest.get(`/events/${eventId}`);
      const event = new Event(data);

      commit('event', event);
      return event;
    } catch (error) {
      notify.error('An error occured while loading Event.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Event');

      return Promise.resolve();
    }
  },
};
