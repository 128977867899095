export default {
  vehicleDetails(currentState, value) {
    const state = currentState;
    state.vehicleDetails = { ...value };
  },

  vehicleResults(currentState, value) {
    const state = currentState;
    state.vehicleResults = Object.assign([], value);
  },

  clientVehicleResults(currentState, value) {
    const state = currentState;
    state.clientVehicleResults = Object.assign([], value);
  },

  domicileResults(currentState, value) {
    const state = currentState;
    state.domicileResults = Object.assign([], value);
  },

};
