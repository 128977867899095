import readXlsxFile from 'read-excel-file';
import Journal from '@/models/fundAllocation/Journal';
import { FUND_ALLOCATION_TABLE_SCHEMA } from '@/config/tableSchema';
import notify from '@/utils/notify';
import fundAllocationHelper from '@/utils/fundAllocation/fundAllocationHelper';
import { cleanValueNumberString } from '@/utils';
import _cloneDeep from 'lodash/cloneDeep';
import { JOURNAL_ENTITY_TYPE, JOURNAL_TYPE_OF_TRANSFER, TYPE_OF_JOURNAL } from '@/config/constants';

export default {

  async uploadFundAllocationTemplate(context, payload) {
    const schema = FUND_ALLOCATION_TABLE_SCHEMA;
    let journals = [];
    await readXlsxFile(payload.files[0], { schema }).then(({ rows, errors }) => {
      const newArray = rows.map((element) => {
        const journal = new Journal(element);
        if (journal.journalEntityType.key === JOURNAL_ENTITY_TYPE.TYPE_F.key) {
          journal.journalEntityType.value = JOURNAL_ENTITY_TYPE.TYPE_F.value;
        } else {
          journal.journalEntityType.value = JOURNAL_ENTITY_TYPE.TYPE_C.value;
        }
        if (journal.journalEntityType.key === JOURNAL_ENTITY_TYPE.TYPE_F.key) {
          if (journal.fundJournal.typeOfTransfer.key === '1') {
            journal.fundJournal.typeOfTransfer.value = JOURNAL_TYPE_OF_TRANSFER.VEHICLE.value;
          } else {
            journal.fundJournal.typeOfTransfer.value = JOURNAL_TYPE_OF_TRANSFER.FUNDCUSTOMER.value;
          }
        }
        if (journal.fundJournal.typeOfJournal.key === TYPE_OF_JOURNAL.key) {
          journal.fundJournal.typeOfJournal.value = TYPE_OF_JOURNAL.value;
        }
        if (journal.narration) {
          const narration = journal.narration.replace(/\s+/g, '');
          if (narration.length > 12) {
            journal.narration = journal.narration.trim().substring(0, 13);
          }
        }
        if (journal.refReg) {
          const refReg = journal.refReg.replace(/\s+/g, '');
          if (refReg.length > 9) {
            journal.refReg = journal.refReg.trim().substring(0, 13);
          }
        }

        journal.journalDate = element.journalDate ? element.journalDate.toISOString().substring(0, 10) : undefined;
        journal.clientJournal.transferAmount = element.clientJournal.transferAmount ? cleanValueNumberString(element.clientJournal.transferAmount) : '0.00';
        journal.fundJournal.incomeAmount = element.fundJournal.incomeAmount ? cleanValueNumberString(element.fundJournal.incomeAmount) : '0.00';
        journal.fundJournal.expensesAmount = element.fundJournal.expensesAmount ? cleanValueNumberString(element.fundJournal.expensesAmount) : '0.00';
        journal.fundJournal.interestAmount = element.fundJournal.interestAmount ? cleanValueNumberString(element.fundJournal.interestAmount) : '0.00';

        return journal;
      });

      if (errors.length > 0 || rows.length === 0) {
        notify.error('An error occured while uploading template.');
        return;
      }

      if (payload.fundAllocation.journals.length > 0) {
        fundAllocationHelper.findDuplicateTransactions(newArray);
        journals = [...payload.fundAllocation.journals, ...JSON.parse(JSON.stringify(newArray))];
      } else {
        fundAllocationHelper.findDuplicateTransactions(newArray);
        journals = JSON.parse(JSON.stringify(newArray));
      }
    });
    this.files = [];
    journals = fundAllocationHelper.limitJournalTransactions(journals);
    return journals;
  },

  addTransactionItem(context, payload) {
    const transactionItem = _cloneDeep(payload);
    let journals = [];
    journals.push(JSON.parse(JSON.stringify(transactionItem.item)));
    if (transactionItem.fundAllocation.journals.length > 0) {
      journals = [...transactionItem.fundAllocation.journals, ...journals];
      journals = fundAllocationHelper.limitJournalTransactions(journals);
    }
    fundAllocationHelper.findDuplicateTransactions(journals);
    return journals;
  },

  acknowledgeDuplicateTransactions(context, payload) {
    const transactionItem = _cloneDeep(payload);
    const acknowleDuplicate = transactionItem.fundAllocation.journals[transactionItem.selectedItemIndex];
    fundAllocationHelper.acknowledgeDuplicateTransactions(transactionItem.fundAllocation.journals, acknowleDuplicate);
    return transactionItem.fundAllocation.journals;
  },

  editTransactionItem(context, payload) {
    const transactionItem = _cloneDeep(payload);
    Object.assign(transactionItem.fundAllocation.journals[transactionItem.selectedItemIndex], transactionItem.item);
    fundAllocationHelper.editTransactionItem(transactionItem.selectedItemIndex, transactionItem.previousItem, transactionItem.item, transactionItem.fundAllocation.journals);
    fundAllocationHelper.findDuplicateTransactions(transactionItem.fundAllocation.journals);
    return transactionItem.fundAllocation.journals;
  },

  removeTransationItem(context, payload) {
    const transactionItem = _cloneDeep(payload);
    const deletedIetem = transactionItem.fundAllocation.journals[transactionItem.selectedItemIndex];
    transactionItem.fundAllocation.journals.splice(transactionItem.selectedItemIndex, 1);
    fundAllocationHelper.deleteDuplicateTrasactions(transactionItem.fundAllocation.journals, deletedIetem);
    return transactionItem.fundAllocation.journals;
  },
};
