import _difference from 'lodash/difference';
import { JOURNAL_ENTITY_TYPE } from '@/config/constants';
import notify from '@/utils/notify';
/* eslint-disable */

function findDuplicateTransactionsTypeC(array) {
  const unique = array.filter((elem, index) => array.findIndex(obj =>
    (obj.clientJournal.clientNumber === elem.clientJournal.clientNumber && obj.clientJournal.transferAmount === elem.clientJournal.transferAmount)) === index);
  const filteredElements = _difference(array, unique);
  array.filter(item => filteredElements.some(filterd => {
    if (item.clientJournal.transferAmount === filterd.clientJournal.transferAmount && item.clientJournal.clientNumber === filterd.clientJournal.clientNumber) {
      if (!item.isAcknowledged) {
        item.isDuplicate = true;
        addDuplicateRecordMessage(item.validationErrors);
      }
    }
  }));
  return array;
}

function findDuplicateTransactionsTypeF(array) {
  const unique = array.filter((elem, index) => array.findIndex(obj =>
  (obj.fundJournal.from.vehicleNumber === elem.fundJournal.from.vehicleNumber && obj.fundJournal.from.clientNumber === elem.fundJournal.from.clientNumber &&
    (obj.fundJournal.incomeAmount === elem.fundJournal.incomeAmount && obj.fundJournal.expensesAmount === elem.fundJournal.expensesAmount && obj.fundJournal.interestAmount === elem.fundJournal.interestAmount))) === index);

  const filteredElements = _difference(array, unique);
  array.filter(item => filteredElements.some(filterd => {
    if (item.fundJournal.from.vehicleNumber === filterd.fundJournal.from.vehicleNumber &&
      (item.fundJournal.incomeAmount === filterd.fundJournal.incomeAmount && item.fundJournal.expensesAmount === filterd.fundJournal.expensesAmount && item.fundJournal.interestAmount === filterd.fundJournal.interestAmount)
      && item.fundJournal.from.clientNumber === filterd.fundJournal.from.clientNumber) {
        if (!item.isAcknowledged) {
        item.isDuplicate = true;
        addDuplicateRecordMessage(item.validationErrors);
      }
    }
  }));
  return array;
}

function addDuplicateRecordMessage(array) {
  if (!array.includes('Duplicate record')) {
    array.push('Duplicate record');
  }
}

function removeDuplicateRecordMessage(array) {
  return array.filter(value => value !== 'Duplicate record');
}

function acknowledgeDuplicateTransactionsF(array, itemObject) {
  array.some(item => {
    if (item.fundJournal.from.vehicleNumber === itemObject.fundJournal.from.vehicleNumber && item.fundJournal.from.clientNumber === itemObject.fundJournal.from.clientNumber
      && (item.fundJournal.incomeAmount === itemObject.fundJournal.incomeAmount && item.fundJournal.expensesAmount === itemObject.fundJournal.expensesAmount && item.fundJournal.interestAmount === itemObject.fundJournal.interestAmount)) {
      item.isAcknowledged = true;
      item.validationErrors = removeDuplicateRecordMessage(item.validationErrors);
    }
  });
}

function acknowledgeDuplicateTransactionsC(array, itemObject) {
   array.some(item => {
    if (item.clientJournal.clientNumber === itemObject.clientJournal.clientNumber && item.clientJournal.transferAmount === itemObject.clientJournal.transferAmount) {
      item.isAcknowledged = true;
       item.validationErrors = removeDuplicateRecordMessage(item.validationErrors);
    }
   });
}
function deleteDuplicateTrasactionC(array, itemObject) {
  const anyDuplicates = array.filter(item => item.clientJournal.transferAmount === itemObject.clientJournal.transferAmount &&
    item.clientJournal.clientNumber === itemObject.clientJournal.clientNumber);
  if (anyDuplicates.length === 1) {
    array.some(item => {
      if (item.clientJournal.transferAmount === itemObject.clientJournal.transferAmount && item.clientJournal.clientNumber === itemObject.clientJournal.clientNumber) {
        item.isDuplicate = false;
        item.isAcknowledged = false;
        item.validationErrors = removeDuplicateRecordMessage(item.validationErrors);
      }
    });
  }
}

function deleteDuplicateTrasactionF(array, itemObject) {
  const anyDuplicates = array.filter(item => item.from.vehicleNumber === itemObject.from.vehicleNumber &&
    (item.fundJournal.incomeAmount === itemObject.fundJournal.incomeAmount && item.fundJournal.expensesAmount === itemObject.fundJournal.expensesAmount && item.fundJournal.interestAmount === itemObject.fundJournal.interestAmount)
    && item.fundJournal.from.clientNumber === itemObject.fundJournal.from.clientNumber);
  if (anyDuplicates.length === 1) {
    array.some(item => {
      if (item.fundJournal.from.vehicleNumber === itemObject.fundJournal.from.vehicleNumber && item.fundJournal.transferAmount === itemObject.fundJournal.transferAmount
        && item.fundJournal.from.clientNumber === itemObject.fundJournal.from.clientNumber) {
        item.isDuplicate = false;
        item.isAcknowledged = false;
        item.validationErrors = removeDuplicateRecordMessage(item.validationErrors);
      }
    });
  }
}

function editTransactionItemTypeC(selectedItemIndex, previousItem, editedItem, transactionArray) {
  if (previousItem.clientJournal.transferAmount !== editedItem.clientJournal.transferAmount
    || previousItem.clientJournal.clientNumber !== editedItem.clientJournal.clientNumber) {
    editedItem.validationErrors = removeDuplicateRecordMessage(editedItem.validationErrors);
    const duplicateItems = transactionArray.filter(item => item.journalEntityType.key === JOURNAL_ENTITY_TYPE.TYPE_C.key
      && item.clientJournal.transferAmount === previousItem.clientJournal.transferAmount && item.clientJournal.clientNumber === previousItem.clientJournal.clientNumber);
    if (duplicateItems.length === 1) {
      transactionArray.some(item => {
        if (item.clientJournal.transferAmount === previousItem.clientJournal.transferAmount
          && item.clientJournal.clientNumber === previousItem.clientJournal.clientNumber) {
          item.isDuplicate = false;
          item.isAcknowledged = false;
          item.validationErrors = removeDuplicateRecordMessage(item.validationErrors);
        }
      });
      editDuplicateTransaction(selectedItemIndex, editedItem, transactionArray);
    } else {
      editDuplicateTransaction(selectedItemIndex, editedItem, transactionArray);
    }
  }
}

function editTransactionItemTypeF(selectedItemIndex, previousItem, editedItem, transactionArray) {
  if (previousItem.fundJournal.incomeAmount !== editedItem.fundJournal.incomeAmount
    || previousItem.fundJournal.expensesAmount !== editedItem.fundJournal.expensesAmount
    || previousItem.fundJournal.interestAmount !== editedItem.fundJournal.interestAmount
    || previousItem.fundJournal.from.clientNumber !== editedItem.fundJournal.from.clientNumber
    || previousItem.fundJournal.from.vehicleNumber !== editedItem.fundJournal.from.vehicleNumber) {
    editedItem.validationErrors = removeDuplicateRecordMessage(editedItem.validationErrors);
    const duplicateItems = transactionArray.filter(item => item.journalEntityType.key === JOURNAL_ENTITY_TYPE.TYPE_F.key
      && item.fundJournal.incomeAmount === previousItem.fundJournal.incomeAmount
      && item.fundJournal.expensesAmount === previousItem.fundJournal.expensesAmount
      && item.fundJournal.interestAmount === previousItem.fundJournal.interestAmount
      && item.fundJournal.from.clientNumber === previousItem.fundJournal.from.clientNumber && item.fundJournal.from.vehicleNumber === previousItem.fundJournal.from.vehicleNumber);
    if (duplicateItems.length === 1) {
      transactionArray.some(item => {
        if (item.fundJournal.incomeAmount === previousItem.fundJournal.incomeAmount
          && item.fundJournal.expensesAmount === previousItem.fundJournal.expensesAmount
          && item.fundJournal.interestAmount === previousItem.fundJournal.interestAmount
          && item.fundJournal.from.clientNumber === previousItem.fundJournal.from.clientNumber && item.fundJournal.from.vehicleNumber === previousItem.fundJournal.from.vehicleNumber) {
          item.isDuplicate = false;
          item.isAcknowledged = false;
          item.validationErrors = removeDuplicateRecordMessage(item.validationErrors);
        }
      });
      editDuplicateTransaction(selectedItemIndex, editedItem, transactionArray);
    } else {
      editDuplicateTransaction(selectedItemIndex, editedItem, transactionArray);
    }
  }
}

function editDuplicateTransaction(selectedItemIndex, editedItem, transactionArray) {
  editedItem.isDuplicate = false;
  editedItem.isAcknowledged = false;
  Object.assign(transactionArray[selectedItemIndex], editedItem);
}

function findDuplicateTransactions(array) {
  const journalEntityTypeC = array.filter(item => item.journalEntityType.key === JOURNAL_ENTITY_TYPE.TYPE_C.key);
  const journalEntityTypeF = array.filter(item => item.journalEntityType.key === JOURNAL_ENTITY_TYPE.TYPE_F.key);
  if (journalEntityTypeC.length > 1) {
    findDuplicateTransactionsTypeC(journalEntityTypeC);
  }
  if (journalEntityTypeF.length > 1) {
    findDuplicateTransactionsTypeF(journalEntityTypeF);
  }
}

function acknowledgeDuplicateTransactions(array, itemObject) {
  if (itemObject.journalEntityType.key === JOURNAL_ENTITY_TYPE.TYPE_C.key) {
    acknowledgeDuplicateTransactionsC(array.filter(item => item.journalEntityType.key === JOURNAL_ENTITY_TYPE.TYPE_C.key), itemObject);
  }
  if (itemObject.journalEntityType.key === JOURNAL_ENTITY_TYPE.TYPE_F.key) {
    acknowledgeDuplicateTransactionsF(array.filter(item => item.journalEntityType.key === JOURNAL_ENTITY_TYPE.TYPE_F.key), itemObject);
  }
}

function deleteDuplicateTrasactions(array, itemObject) {
  if (itemObject.journalEntityType.key === JOURNAL_ENTITY_TYPE.TYPE_C.key && itemObject.isDuplicate) {
    deleteDuplicateTrasactionC(array, itemObject);
  }
  if (itemObject.journalEntityType.key === JOURNAL_ENTITY_TYPE.TYPE_F.key && itemObject.isDuplicate) {
    deleteDuplicateTrasactionF(array, itemObject);
  }
}

function editTransactionItem(selectedItemIndex, previousItem, editedItem, transactionArray) {
  if (editedItem.journalEntityType.key === JOURNAL_ENTITY_TYPE.TYPE_C.key) {
    editTransactionItemTypeC(selectedItemIndex, previousItem, editedItem, transactionArray);
  }
  if (editedItem.journalEntityType.key === JOURNAL_ENTITY_TYPE.TYPE_F.key) {
    editTransactionItemTypeF(selectedItemIndex, previousItem, editedItem, transactionArray);
  }
}

function limitJournalTransactions(journals) {
  const maxJournals = 300;
  if (journals.length > maxJournals) {
    journals.splice(maxJournals, journals.length - maxJournals);
    notify.error('Only 300 transactions (Journals) can be added for a fund allocation request.');
  }
  return journals;
}

export default {
  findDuplicateTransactions,
  acknowledgeDuplicateTransactions,
  deleteDuplicateTrasactions,
  editTransactionItem,
  limitJournalTransactions
};
