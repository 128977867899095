export default {
  vehicleCaptures(currentState, value) {
    const state = currentState;
    state.vehicleCaptures = value;
  },
  vehicleCapture(currentState, value) {
    const state = currentState;
    state.vehicleCapture = value;
  },
};
