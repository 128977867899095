import notify from '@/utils/notify';
import rest from '@/plugins/rest';

export default {

  async getAuthorityGroups({ commit }, clientId) {
    try {
      const { data } = await rest.get(`/clients/${clientId}/authority-groups`);
      commit('authorityGroupsResults', data);

      return data;
    } catch (error) {
      notify.error('An error occured while loading the authority groups Details.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get authotity groups Details');

      return Promise.resolve();
    }
  },
};
