import axios from 'axios';
import notify from '@/utils/notify';
import rest from '@/plugins/rest';

import Vehicle from '@/models/Vehicle';
import ErrorEvent from '@/models/ErrorEvent';

let cancel;
const { CancelToken } = axios;

export default {
  async searchVehicles({ commit }, searchString) {
    try {
      if (cancel !== undefined) {
        cancel();
      }

      const { data } = await rest.post('/vehicles/search', {
        registrationNumber: searchString.toUpperCase(),
      },
      {
        cancelToken: new CancelToken(((c) => {
          cancel = c;
        })),
      });

      commit('vehicleResults', data);
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return null;
      }

      notify.error('An error occured while searching for Vehicles. Please try again later.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Search Vehicles');

      return Promise.resolve();
    }
  },

  async searchVehiclesDescriptions({ commit }, searchString) {
    try {
      if (cancel !== undefined) {
        cancel();
      }

      const { data } = await rest.post('/vehicles/description-search', {
        vehicleMakeDescription: searchString,
      },
      {
        cancelToken: new CancelToken(((c) => {
          cancel = c;
        })),
      });

      commit('vehicleResults', data);
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return null;
      }

      notify.error('An error occured while searching for Vehicles. Please try again later.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Search Vehicles');

      return Promise.resolve();
    }
  },

  async searchVehiclesDomiciles({ commit }, searchString) {
    try {
      if (cancel !== undefined) {
        cancel();
      }

      const { data } = await rest.post('/vehicles/domicile-search', {
        domicile: searchString,
      },
      {
        cancelToken: new CancelToken(((c) => {
          cancel = c;
        })),
      });

      commit('domicileResults', data);
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return null;
      }

      notify.error('An error occured while searching for ACB Domiciles. Please try again later.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Search ACB Domiciles');

      return Promise.resolve();
    }
  },

  async getVehicleDetails({ commit }, registrationNumber) {
    try {
      const { data } = await rest.get(`/vehicles/${registrationNumber}`);
      const vehicleData = new Vehicle(data);

      commit('vehicleDetails', vehicleData);
      return vehicleData;
    } catch (error) {
      notify.error('An error occured while loading the Vehicle Details.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Vehicle Details');

      return Promise.resolve();
    }
  },
  async getClientVehicles({ commit }, clientId) {
    try {
      const { data } = await rest.get(`/client/${clientId}/vehicles`);
      commit('clientVehicleResults', data);
      return data;
    } catch (error) {
      notify.error('An error occured while loading the Client Vehicle Details.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Billing Client Vehicle Details');

      return Promise.resolve();
    }
  },

  async getVehicleMakes() {
    try {
      const { data } = await rest.get('/vehicles/makes');

      return data;
    } catch (error) {
      notify.error('An error occured while loading the Vehicle Makes.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Getting Vehicle Makes');

      return Promise.resolve();
    }
  },

  async getUsageCodes() {
    try {
      const { data } = await rest.get('/vehicles/usage-codes');
      return data;
    } catch (error) {
      notify.error('An error occured while loading the Vehicle Codes.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Getting Vehicle Codes');

      return Promise.resolve();
    }
  },

  async getGeneralCodes(context, code) {
    try {
      const { data } = await rest.get(`/vehicles/general-codes?code=${code}`);
      return data;
    } catch (error) {
      notify.error('An error occured while loading the General Codes.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Getting General Codes');

      return Promise.resolve();
    }
  },

  async getVehicleModels(context, make) {
    try {
      const { data } = await rest.get(`/vehicles/models?makes=${make}`);
      return data;
    } catch (error) {
      notify.error('An error occured while loading the Vehicle Models.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Getting Vehicle Models');

      return Promise.resolve();
    }
  },
  async getVehiclesByMMCode(context, mmCode) {
    try {
      const { data } = await rest.get(`/vehicles/mmcode?mmCode=${mmCode}`);
      return data;
    } catch (error) {
      notify.error('An error occured while loading the Vehicle Using MMCode.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Getting Vehicle Using MMCode');

      return Promise.resolve();
    }
  },

  async getVehicleYears(context, payload) {
    try {
      const { make, model } = payload;
      const { data } = await rest.get(`/vehicles/vehicleYear?makes=${make}&model=${model}`);
      return data;
    } catch (error) {
      notify.error('An error occured while loading the Vehicle Years.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Getting Vehicle Years');

      return Promise.resolve();
    }
  },

  async getVehicleDescriptions(context, payload) {
    try {
      const { make, model, year } = payload;
      const { data } = await rest.get(`/vehicles/variant?makes=${make}&model=${model}&year=${year}`);
      return data;
    } catch (error) {
      notify.error('An error occured while loading the Vehicle Variant.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Getting Vehicle Variant');

      return Promise.resolve();
    }
  },

  async getFuelCapacity(context, payload) {
    try {
      const { make, model, variant, year } = payload;
      const { data } = await rest.get(`/vehicles/fueltank?makes=${make}&model=${model}&variant=${variant}&year=${year}`);
      return data;
    } catch (error) {
      notify.error('An error occured while loading the Vehicle Fuel Tank Capacity.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Getting Vehicle Fuel Tank Capacity');

      return Promise.resolve();
    }
  },
  async getRegionByCountry(context, country) {
    try {
      const { data } = await rest.get(`/vehicles/ort-regions?country=${country}`);
      return data;
    } catch (error) {
      notify.error('An error occured while loading the regions.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Getting regions');

      return Promise.resolve();
    }
  },
  async getCountries() {
    try {
      const { data } = await rest.get('/vehicles/ort-countries');

      return data;
    } catch (error) {
      notify.error('An error occured while loading the countries.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Getting countries');

      return Promise.resolve();
    }
  },
  async getOrtIndicators(context, payload) {
    try {
      const { clientId, registrationNumber, modelExtention } = payload;
      const { data } = await rest.get(`/vehicles/ort-indicators?clientId=${clientId}&registrationNumber=${registrationNumber}&modelExtention=${modelExtention}`);
      return data;
    } catch (error) {
      notify.error('An error occured while loading the ort Indicators.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Getting ort Indicators');

      return Promise.resolve();
    }
  },
};
