import jwt from 'jsonwebtoken';
import moment from 'moment';
import axios from 'axios';
import qs from 'querystring';
import crypto from 'crypto';
import { KJUR, KEYUTIL } from 'jsrsasign';
import UserId from '@/models/UserId';

import store from '@/store';
import { SORT_ORDER } from '../config/constants';

export function addLeadingZero(number) {
  return parseInt(number, 10) <= 9 ? `0${number}` : number;
}

export function formatDate(date) {
  if (parseInt(date, 10) > 0) {
    const convertedDate = new Date(parseInt(date, 10));
    return `${convertedDate.getFullYear()}-${addLeadingZero((convertedDate.getMonth() + 1))}-${addLeadingZero(convertedDate.getDate())}`;
  }
  return '-';
}

export function stringDateFormat(date) {
  if (date && date !== '' && date !== '0') {
    return `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}`;
  }
  return '-';
}

export function commaSeparatedStringToArray(str) {
  return str.replace(/\s/g, '').split(',');
}

export function addUserIdsDetails(ids, userType) {
  return commaSeparatedStringToArray(ids).map(id => new UserId({
    id, companyName: 'N/A', userType, selected: false,
  }));
}

export function base64URLEncode(str) {
  return str.toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

export function sha256(buffer) {
  return crypto.createHash('sha256').update(buffer).digest();
}

export function isJwtExpired(jwtToken) {
  try {
    if (!jwtToken || jwtToken === '') {
      throw new Error('Cannot parse empty jwt token.');
    }

    const decodedJwt = jwt.decode(jwtToken);
    // PS - exp from JWT is in milliseconds, although it should be in seconds.
    return new Date() > new Date(decodedJwt.exp * 1000);
  } catch (exception) {
    return {
      message: 'Could not determine expiry date.',
      error: exception.toString(),
    };
  }
}

export async function validateIdToken(token) {
  try {
    // Get the nonce from sessionStorage
    const nonce = sessionStorage.getItem('nonce');

    // Get pub keys from JWKS
    const { data } = await axios.get(process.env.VUE_APP_AUTH_JWKS);
    const { keys } = data;
    const decodedToken = jwt.decode(token);
    const tokenHeader = JSON.parse(atob(token.split('.')[0]));
    const { kid } = tokenHeader;

    // Find the pub key using the kid value
    const key = keys.find((x) => x.kid === kid);

    if (key) {
      const rsaKey = KEYUTIL.getKey(key);
      const options = {
        alg: ['RS256'],
        iss: [process.env.VUE_APP_AUTH_ISS],
        sub: [decodedToken.sub],
        aud: [process.env.VUE_APP_AUTH_CLIENT_ID],
        jti: decodedToken.jti,
        gracePeriod: 2 * 60 * 60,
      };

      const idTokenIsValid = KJUR.jws.JWS.verifyJWT(token, rsaKey, options) && decodedToken.nonce === nonce;

      return idTokenIsValid
        ? { isIdTokenValid: true, decodedToken }
        : { isIdTokenValid: false, error: 'Invalid id token' };
    }
    return { isIdTokenValid: false, error: 'No matching RSA key found in JWKS' };
  } catch (error) {
    return { isIdTokenValid: false, error: 'An error occured while validating your token' };
  }
}

// THIS FUNCTION IS OUTDATED AND WILL NEED AN UPDATE WHEN
// REFRESH TOKENS ARE PROPERLY IMPLEMENTED
// THIS FUNCTION IS NOT CURRENTLY BEING USED
export async function refreshJWTToken(refreshToken) {
  try {
    const requestBody = {
      refresh_token: refreshToken,
      redirect_uri: process.env.VUE_APP_AUTH_REDIRECT_URI,
      grant_type: 'refresh_token',
      client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
      code_verifier: process.env.VUE_APP_AUTH_CODE_VERIFIER,
    };

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const result = await axios.post(process.env.VUE_APP_AUTH_MAIN_URL, qs.stringify(requestBody), config);

    sessionStorage.setItem('accessToken', result.data.access_token); // REWRITE ACCESS LOCAL STORAGE
    sessionStorage.setItem('refreshToken', result.data.refresh_token); // REWRITE REFRESH LOCAL STORAGE
  } catch (error) {
    if (error.response.data.error === 'invalid_grant') {
      await store.dispatch('session/logout');
    }
  }
}

export function readFileBase64(inputFile) {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    temporaryFileReader.onload = () => {
      resolve(window.btoa(temporaryFileReader.result));
    };
    temporaryFileReader.readAsBinaryString(inputFile);
  });
}

export function readFile(inputFile) {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsBinaryString(inputFile);
  });
}

export function base64toBlob(base64Data, contentTypeValue) {
  const contentType = contentTypeValue || '';
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = [slicesCount];

  // eslint-disable-next-line
  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = [end - begin];
    // eslint-disable-next-line
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export function getCurrentTimestamp() {
  return moment().format('DD-MM-YYYY HH:mm:ss');
}

export function mapObject(arrayList, ItemClass, applyDefaults = false) {
  const returnValue = [];

  if (arrayList) {
    arrayList.forEach((element) => {
      if (element && typeof element === 'object') {
        if (ItemClass) {
          returnValue.push(new ItemClass(element, applyDefaults));
        } else {
          returnValue.push(element);
        }
      }
    });
  }
  return returnValue;
}

export function sortStringDateArray(dates, order) {
  return dates.sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);

    switch (order) {
      case SORT_ORDER.ASC:
        return dateA - dateB;
      case SORT_ORDER.DESC:
        return dateB - dateA;
      default:
        return dateA - dateB;
    }
  });
}

export function cleanValueNumberString(valueString) {
  // eslint-disable-next-line no-restricted-globals
  return (!isNaN(valueString))
    ? parseFloat(valueString).toFixed(2)
    : parseFloat(Number(valueString.replace(/(?!^-)[^0-9|\\.]/g, ''))).toFixed(2);
}
