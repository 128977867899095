export default {
  redirectUrl(currentState, value) {
    const state = currentState;
    state.redirectUrl = value;
  },

  sidebar(currentState, value) {
    const state = currentState;
    state.sidebar = value;
  },

  numberOfElements(currentState, value) {
    const state = currentState;
    state.numberOfElements = parseInt(value, 10);
  },
};
