export default {
  event(currentState, value) {
    const state = currentState;
    state.event = { ...value };
  },

  events(currentState, value) {
    const state = currentState;
    state.events = value;
  },

  eventTask(currentState, value) {
    const state = currentState;
    state.eventTask = { ...value };
  },

  eventTasks(currentState, value) {
    const state = currentState;
    state.eventTasks = value;
  },

};
