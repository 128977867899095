import notify from '@/utils/notify';
// import rest from '@/plugins/rest';

export default {
  async getSample({ commit }, id) {
    try {
      commit('sampleData', id);

      return Promise.resolve();
    } catch (error) {
      notify.error('An error occured.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Template Context');

      return Promise.resolve();
    }
  },
};
