export default {
  attachment(currentState, value) {
    const state = currentState;
    state.attachment = { ...value };
  },

  attachments(currentState, value) {
    const state = currentState;
    state.attachments = Object.assign([], value);
  },
};
