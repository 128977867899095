import axios from 'axios';
import notify from '@/utils/notify';
import rest from '@/plugins/rest';

let cancel;
const { CancelToken } = axios;

export default {

  async searchTransactionCodes({ commit }, searchString) {
    try {
      if (cancel !== undefined) {
        cancel();
      }

      const { data } = await rest.post('/client/transactionCodes/search', {
        transactionCode: searchString,
      },
      {
        cancelToken: new CancelToken(((c) => {
          cancel = c;
        })),
      });

      commit('transactionCodeResults', data);
      return data;
    } catch (error) {
      if (axios.isCancel(error)) {
        return null;
      }

      notify.error('An error occured while searching for Transaction Code. Please try again later.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Search Transaction Code');

      return Promise.resolve();
    }
  },
};
