import _cloneDeep from 'lodash/cloneDeep';

export default {
  merchantDetails(currentState, value) {
    const state = currentState;
    state.merchantDetails = { ...value };
  },

  merchantResults(currentState, value) {
    const state = currentState;
    state.merchantResults = Object.assign([], value);
  },

  commissionCharges(currentState, value) {
    const state = currentState;
    state.commissionCharges = _cloneDeep(value);
  },
};
