import { required, email, max, min, numeric } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('required', {
  ...required,
  message: '{_field_} is required',
});

extend('selected', {
  validate: (value) => !!value,
  message: '{_field_} must be selected',
});

extend('email', {
  ...email,
  message: '{_field_} must be a valid email',
});

extend('max', {
  ...max,
  message: '{_field_} must be max {length} characters',
});

extend('min', {
  ...min,
  message: '{_field_} value must be min {length}',
});

extend('numeric', {
  ...numeric,
  message: '{_field_} must be min {length} value',
});
