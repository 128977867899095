import qs from 'querystring';
import axios from 'axios';
import _startCase from 'lodash/startCase';

import router from '@/router';
import notify from '@/utils/notify';

import ErrorEvent from '@/models/ErrorEvent';
import User from '@/models/User';

import { USER_ROLES, LOGIN_NAMES } from '@/config/constants';
import { validateIdToken, addUserIdsDetails } from '@/utils';
import { setIdToken, setAccessToken, setUserGroups, setUserIds } from '@/workers/session.worker';

export default {
  async authenticate({ dispatch, rootGetters }, payload) {
    try {
      const { authCode, errorCode } = payload;

      if (errorCode) {
        notify.error(`An error occured: ${errorCode}`);
        const userType = localStorage.getItem('userType') || 'merchant';
        switch (userType) {
          case 'staff':
            this.$router.push({ name: 'StaffLogin' });
            break;
          case 'merchant':
            this.$router.push({ name: 'MerchantLogin' });
            break;
          default:
            break;
        }
        return null;
      }

      // Get the verifier from the auth store and clear it
      const verifier = sessionStorage.getItem('verifier');
      sessionStorage.removeItem('verifier');

      const requestBody = {
        code: authCode,
        redirect_uri: process.env.VUE_APP_AUTH_REDIRECT_URI,
        grant_type: process.env.VUE_APP_AUTH_GRANT_TYPE,
        client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
        code_verifier: verifier,
      };

      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      const url = process.env.VUE_APP_AUTH_MAIN_URL;
      const { data } = await axios.post(url, qs.stringify(requestBody), config);

      // Validate the ID Token
      const tokenValidation = await validateIdToken(data.id_token);
      sessionStorage.removeItem('nonce');

      if (!tokenValidation.isIdTokenValid) {
        notify.warning(tokenValidation.error);

        await dispatch('clearStorage');

        const userType = localStorage.getItem('userType') || 'merchant';
        router.push({
          name: LOGIN_NAMES[userType],
        });

        return null;
      }

      // Store the tokens in memory using web workers
      await setAccessToken(data.access_token);
      await setIdToken(data.id_token);

      // Get user info to get groups
      await dispatch('getUserInfo', data.access_token);

      const redirectUrl = rootGetters['userSettings/redirectUrl'];

      if (redirectUrl && redirectUrl.trim().length > 0) {
        router.push(redirectUrl);
      } else {
        router.push({ name: 'Home' });
      }

      return Promise.resolve();
    } catch (error) {
      notify.error('An error occured while trying to Authenticate you.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Authenticate User Token');

      const userType = localStorage.getItem('userType') || 'merchant';
      router.push({
        name: LOGIN_NAMES[userType],
      });

      return Promise.resolve();
    }
  },

  async getUserInfo({ commit, getters }, accessToken) {
    try {
      const userInfoUrl = process.env.VUE_APP_AUTH_USERINFO_URL;
      const config = {
        headers: {
          Authorization: `bearer ${accessToken}`,
        },
      };

      const { data } = await axios.get(userInfoUrl, config);

      const authUser = new User({
        username: data.employee_number || data.merchantID || data.customer_number,
        emailAddress: data.sub,
        displayName: _startCase(data.name),
        groups: [],
        userIds: [],
      });

      const staffUserIds = data.employee_number ? addUserIdsDetails(data.employee_number, 'staff') : [];
      const merchantUserIds = data.merchantID ? addUserIdsDetails(data.merchantID, 'merchant') : [];
      const customerUserIds = data.customer_number ? addUserIdsDetails(data.customer_number, 'customer') : [];

      authUser.userIds = staffUserIds.concat(merchantUserIds, customerUserIds);
      authUser.userIds[0].selected = true;
      authUser.username = authUser.userIds[0].id;

      // If only one groups is set on the user, it will come back as
      // a single string instead of the expected array
      if (data.memberOf) {
        if (data.memberOf.constructor.name === 'String') {
          authUser.groups = [data.memberOf];
        } else if (data.memberOf.constructor.name === 'Array') {
          authUser.groups = data.memberOf;
        } else {
          authUser.groups = [];
        }
      }

      // Check the user type for group override
      const userType = localStorage.getItem('userType');

      if (userType === 'merchant') {
        authUser.groups = USER_ROLES.merchantExternal.groups;
      } else if (userType === 'customer') {
        authUser.groups = USER_ROLES.customer.groups;
      }

      // Store the user groups for use later in the router guards
      await setUserGroups(authUser.groups);
      await setUserIds(authUser.userIds);

      notify.success(`Welcome ${authUser.displayName}, you're successfully logged in!`);

      commit('user', authUser);
      return authUser;
    } catch (error) {
      notify.error('An error occured while trying to find user info.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Auth Get User Info');
      return Promise.resolve();
    } finally {
      // TEMPORARILY DISABLE AUTH USER FOR OVERRIDE
      const { tempUser } = getters;

      if (tempUser && tempUser.username !== undefined) {
        commit('user', tempUser);
      }
    }
  },

  async logout({ dispatch }) {
    const userType = localStorage.getItem('userType');
    let authUrl = '';

    // Check userType and get AzureAD or B2C logout url
    if (userType === 'merchant' || userType === 'customer') {
      authUrl = process.env.VUE_APP_AUTH_B2C_LOGOUT.replace('{{redirectUrl}}', window.location.origin);
    } else {
      authUrl = process.env.VUE_APP_AUTH_LOGOUT.replace('{{redirectUrl}}', window.location.origin);
    }

    // Build logout url based on current user type for redirect
    authUrl = `${authUrl}/${userType}/login`;

    // Clear all storage items
    await dispatch('clearStorage');

    notify.info('You have been logged out');
    window.location.href = authUrl;
  },

  clearStorage({ commit }) {
    sessionStorage.clear();

    commit('userSettings/redirectUrl', '');
  },
};
