import notify from '@/utils/notify';
import rest from '@/plugins/rest';

import ReferenceList from '@/models/ReferenceList';
import ErrorEvent from '@/models/ErrorEvent';

export default {

  async getReferenceList(context, referenceType) {
    try {
      const { data } = await rest.get(`/reference-data/${referenceType}`);

      if (data.length === 0) {
        return [];
      }

      return data.map((x) => new ReferenceList(x));
    } catch (error) {
      notify.error('An error occured while loading Reference List.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Reference List');

      return Promise.resolve();
    }
  },

};
