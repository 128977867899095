import notify from '@/utils/notify';
import rest from '@/plugins/rest';

export default {

  async getBillingCentres({ commit }, clientId) {
    try {
      // this is how we call the api and passing the endpoints
      const { data } = await rest.get(`/clients/${clientId}/billing-centres`);
      commit('billingCentreResults', data);
      return data;
    } catch (error) {
      notify.error('An error occured while loading the Billing Centre Details.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Billing Centre Details');

      return Promise.resolve();
    }
  },

  async getCostBillingCentres({ commit }, clientId) {
    try {
      // this is how we call the api and passing the endpoints
      const { data } = await rest.get(`/clients/${clientId}/cost-billing-centres`);
      commit('billingCentreResults', data);
      return data;
    } catch (error) {
      notify.error('An error occured while loading the Billing Centre Details.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Billing Centre Details');

      return Promise.resolve();
    }
  },
};
