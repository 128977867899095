import notify from '@/utils/notify';
import rest from '@/plugins/rest';
import _cloneDeep from 'lodash/cloneDeep';

export default {

  async getClientAddress({ commit }, clientId) {
    try {
      const { data } = await rest.get(`/clients/${clientId}/delivery-address`);
      commit('clientAddressResults', data);

      return data;
    } catch (error) {
      notify.error('An error occured while loading the client address Details.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get client Address Details');

      return Promise.resolve();
    }
  },
  addAddressItem(context, payload) {
    const addressItem = _cloneDeep(payload);
    let adress = [];
    adress.push(JSON.parse(JSON.stringify(addressItem.item)));

    if (addressItem.vehicle.cardDelivery.adress.length > 0) {
      adress = [...addressItem.vehicle.cardDelivery.adress, ...adress];
    }
    return adress;
  },

};
