import _cloneDeep from 'lodash/cloneDeep';
import notify from '@/utils/notify';
import rest from '@/plugins/rest';
import axios from 'axios';

import { SR_STATUSES, SR_APPLICATION_TYPES, SERVICE_TYPE_LIST } from '@/config/constants';

import ServiceRequest from '@/models/ServiceRequest';
import Task from '@/models/Task';
import Pagination from '@/models/Pagination';
import Audit from '@/models/Audit';
import ErrorEvent from '@/models/ErrorEvent';

let cancel;
const { CancelToken } = axios;

export default {
  async createServiceRequest({ commit, rootGetters }, payload) {
    try {
      const serviceRequest = _cloneDeep(payload);
      if (serviceRequest.applicationType.key !== SR_APPLICATION_TYPES.SR_APPLICATION_TYPE_FUND_ALLOCATION && serviceRequest.applicationType.key !== SR_APPLICATION_TYPES.SR_APPLICATION_TYPE_VEHICLE_CAPTURE) {
        const isRequired = serviceRequest.serviceType.key === SERVICE_TYPE_LIST.SR_SERVICE_TYPE_MAINTENANCE_AUTHORISATION.key;
        serviceRequest.managedAuthorisation.isRequired = isRequired;
        // Convert totals and item pricing to strings
        serviceRequest.authorisation.jobCard.sectionsToString();
      }
      const currentUser = rootGetters['session/user'];
      serviceRequest.audit.createdBy = currentUser.displayName;
      serviceRequest.audit.createdById = currentUser.username;
      serviceRequest.audit.createdByEmail = currentUser.emailAddress;

      const createServiceRequestResult = await rest.post('/service-requests', serviceRequest);

      serviceRequest.serviceRequestNumber = createServiceRequestResult.data.serviceRequestId;

      commit('serviceRequest', serviceRequest);
      return serviceRequest.serviceRequestNumber;
    } catch (error) {
      notify.error(`Failed to Create Service Request: ${error.response.statusText}`);

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Create Service Request');

      return Promise.resolve();
    }
  },

  async updateServiceRequest({ rootGetters }, payload) {
    try {
      const { serviceRequestNumber } = payload;
      const currentUser = rootGetters['session/user'];
      const audit = new Audit();
      audit.createdBy = currentUser.displayName;
      audit.createdById = currentUser.username;
      audit.createdByEmail = currentUser.emailAddress;
      audit.auditLog = payload.audit.auditLog;

      const serviceRequest = {
        status: payload.status,
        managedAuthorisation: payload.managedAuthorisation,
        audit,
      };

      await rest.patch(`/service-requests/${serviceRequestNumber}`, serviceRequest);

      notify.success('Service Request Successfully Saved');
      return Promise.resolve();
    } catch (error) {
      notify.error('Failed to Update Service Request. Please try again later.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Update Service Request');

      return Promise.resolve();
    }
  },

  async updateServiceRequestEx({ rootGetters }, payload) {
    try {
      const serviceRequest = _cloneDeep(payload.serviceRequest);
      const audit = new Audit(payload.audit);
      const { serviceRequestNumber } = serviceRequest;
      const currentUser = rootGetters['session/user'];
      if (serviceRequest.applicationType.key !== SR_APPLICATION_TYPES.SR_APPLICATION_TYPE_FUND_ALLOCATION) {
        // Convert totals and item pricing to strings
        serviceRequest.authorisation.jobCard.sectionsToString();
      }

      serviceRequest.audit.createdBy = currentUser.displayName;
      serviceRequest.audit.createdById = currentUser.username;
      serviceRequest.audit.createdByEmail = currentUser.emailAddress;
      serviceRequest.audit.event = audit.event;
      serviceRequest.audit.comments = audit.comments;

      await rest.patch(`/service-requests/${serviceRequestNumber}`, serviceRequest);

      notify.success('Service Request Successfully Saved');
      return Promise.resolve();
    } catch (error) {
      notify.error('Failed to Update Service Request. Please try again later.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Update Service Request Extended');

      return Promise.resolve();
    }
  },

  async updateServiceRequestV({ rootGetters }, payload) {
    try {
      const serviceRequest = _cloneDeep(payload.serviceRequest);
      const audit = new Audit(payload.audit);
      const { serviceRequestNumber } = serviceRequest;
      const currentUser = rootGetters['session/user'];

      serviceRequest.audit.createdBy = currentUser.displayName;
      serviceRequest.audit.createdById = currentUser.username;
      serviceRequest.audit.createdByEmail = currentUser.emailAddress;
      serviceRequest.audit.event = audit.event;
      serviceRequest.audit.comments = audit.comments;

      await rest.patch(`/service-requests/${serviceRequestNumber}`, serviceRequest);

      notify.success('Service Request Successfully Saved');
      return Promise.resolve();
    } catch (error) {
      notify.error('Failed to Update Service Request. Please try again later.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Update Service Request Extended');

      return Promise.resolve();
    }
  },

  async completeServiceRequest({ rootGetters }, payload) {
    try {
      const { serviceRequestNumber } = payload;
      const currentUser = rootGetters['session/user'];
      const audit = new Audit();

      audit.createdBy = currentUser.displayName;
      audit.createdById = currentUser.username;
      audit.createdByEmail = currentUser.emailAddress;
      audit.auditLog = payload.audit.auditLog;
      const serviceRequest = {
        status: {
          key: SR_STATUSES.SR_STATUS_COMPLETED.value,
        },
        managedAuthorisation: payload.managedAuthorisation,
        audit,
      };

      await rest.patch(`/service-requests/${serviceRequestNumber}`, serviceRequest);

      notify.success('Service Request Successfully Saved');
      return Promise.resolve();
    } catch (error) {
      notify.error('Failed to Complete Service Request. Please try again later.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Complete Service Request');

      return Promise.resolve();
    }
  },

  async actionServiceRequest({ rootGetters }, payload) {
    try {
      const { serviceRequestNumber, action, reasons, reason, applicationType } = payload;
      const currentUser = rootGetters['session/user'];

      const actionRequest = {
        action,
        reasons,
        reason,
        applicationType,
        actionedBy: currentUser.displayName,
        actionedById: currentUser.username,
      };

      await rest.post(`/service-requests/${serviceRequestNumber}/action`, actionRequest);

      notify.success('Service Request Successfully Saved');
      return Promise.resolve();
    } catch (error) {
      notify.error('Failed to Update Service Request. Please try again later.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Update Service Request Extended');

      return Promise.resolve();
    }
  },

  getEmptyPaginationResponse({ rootGetters }) {
    const pagination = new Pagination();
    pagination.numberOfElements = rootGetters['userSettings/numberOfElements'];
    return { serviceRequests: [], pagination };
  },

  async getServiceRequests({ commit, dispatch }, payload) {
    try {
      if (cancel !== undefined) {
        cancel();
      }

      const { status, srType, authUser, filterValue, page, pageSize, dateRange } = payload;
      const { username, isMerchant, isCustomer, isStaffNumber } = authUser;

      // Clear Service Requests
      commit('serviceRequests', []);
      let userFilter;
      const dateFrom = dateRange ? dateRange[0] || '' : '';
      const dateTo = dateRange ? dateRange[1] || '' : '';

      if (isMerchant && !isStaffNumber) {
        userFilter = `&merchantNumber=${username}`;
      } else if (isCustomer && !isStaffNumber) {
        userFilter = `&clientNumber=${username}`;
      } else {
        userFilter = '';
      }

      const statusParam = `status=${status}`;
      const applicationTypeParam = `applicationType=${srType}${userFilter}`;
      const pageParam = `page=${page}`;
      const pageSizeParam = `pageSize=${pageSize}`;
      const filterValueParam = `filterValue=${filterValue}`;
      const dateFromParam = `dateFrom=${dateFrom}`;
      const dateToParam = `dateTo=${dateTo}`;

      const url = `/service-requests?${statusParam}&${applicationTypeParam}&${pageParam}&${pageSizeParam}&${filterValueParam}&${dateFromParam}&${dateToParam}`;
      const { data: results } = await rest.get(url, {
        cancelToken: new CancelToken(c => {
          cancel = c;
        }),
      });

      if (results.data.length === 0) {
        commit('serviceRequests', []);
        return await dispatch('getEmptyPaginationResponse');
      }

      const serviceRequests = results.data.map(x => new ServiceRequest(x));
      commit('serviceRequests', serviceRequests);

      return { serviceRequests, pagination: new Pagination(results.pagination) };
    } catch (error) {
      const emptyResponse = await dispatch('getEmptyPaginationResponse');

      if (axios.isCancel(error)) {
        return emptyResponse;
      }

      notify.error('An error occured while loading Service Requests.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Service Requests');

      return emptyResponse;
    }
  },

  async getServiceRequest(context, serviceRequestNumber) {
    try {
      const { data } = await rest.get(`/service-requests/${serviceRequestNumber}`);

      return new ServiceRequest(data);
    } catch (error) {
      notify.error('An error occured while loading the Service Request.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Service Request');

      return Promise.resolve();
    }
  },

  async getServiceRequestStatus(context, serviceRequestNumber) {
    try {
      const { data } = await rest.get(`/service-requests/${serviceRequestNumber}/status`);

      return data;
    } catch (error) {
      const { response } = error;
      if (response && response.status === 400) {
        return Promise.resolve({ status: '400' });
      }
      notify.error('An error occured while loading the Service Request Status.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Service Request Status');

      return Promise.resolve();
    }
  },

  async getServiceRequestTasks({ commit }, payload) {
    try {
      commit('serviceRequestTasks', []);

      const { data } = await rest.get(`/service-requests/${payload.serviceRequestNumber}/tasks`);

      if (data.length === 0) {
        return [];
      }

      return data.map(x => new Task(x));
    } catch (error) {
      notify.error('An error occured while loading Service Request Tasks.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Service Request Tasks');

      return Promise.resolve();
    }
  },

  async getServiceRequestTask({ commit }, payload) {
    try {
      const { data } = await rest.get(`/service-requests/${payload.serviceRequestNumber}/tasks/${payload.taskId}`);
      const serviceRequestTask = new Task(data);

      commit('serviceRequestTask', serviceRequestTask);
      return serviceRequestTask;
    } catch (error) {
      notify.error('An error occured while loading the Service Request Task.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get Service Request Task');

      return Promise.resolve();
    }
  },
};
