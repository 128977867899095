import Vue from 'vue';
import VueRouter from 'vue-router';

import notify from '@/utils/notify';
import { isJwtExpired } from '@/utils';
import menuItems from '@/config/menuItems';
import { getAccessToken, getUserGroups } from '@/workers/session.worker';
import { LOGIN_NAMES } from '../config/constants';
import store from '../store';

const Login = () => import(/* webpackChunkName: "auth" */ '@/views/session/Login');
const Main = () => import(/* webpackChunkName: "main" */ '@/layouts/Main');
const Blank = () => import(/* webpackChunkName: "blank" */ '@/layouts/Blank');

Vue.use(VueRouter);

const routes = [
  /** ************************ */
  /*    HOME                  */
  /** ********************** */
  {
    path: '/',
    component: Main,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
      },
    ],
  },

  /** ************************ */
  /*    SLA MANAGEMENT        */
  /** ********************** */
  {
    path: '/sla-management/',
    component: Main,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/sla-management/dashboard',
        name: 'SlaManagementDashboard',

        component: () => import(/* webpackChunkName: "sla-dashboard" */ '@/views/slaManagement/Dashboard'),
      },
      // ////////////////////////
      // Create Service Request
      {
        path: '/sla-management/create-service-request',
        name: 'SlaManagementCreateServiceRequest',
        component: () => import(/* webpackChunkName: "sla-service-requests" */ '@/views/slaManagement/CreateServiceRequest'),
      },
      // ////////////////////////
      // Service Requests Inbox
      {
        path: '/sla-management/service-requests',
        name: 'SlaManagementServiceRequestsInbox',
        component: () => import(/* webpackChunkName: "sla-service-requests" */ '@/views/slaManagement/inbox/ServiceRequestInbox'),
      },

      // ////////////////////////
      // Service Request Page
      {
        path: '/sla-management/service-requests/:serviceRequestNumber',
        name: 'SlaManagementServiceRequest',
        props: true,
        component: () => import(/* webpackChunkName: "sla-service-requests" */ '@/views/slaManagement/serviceRequests/ServiceRequestDetailsPage'),
      },

      // ////////////////////////
      // Task Detail Page
      {
        path: '/sla-management/service-request/:serviceRequestNumber/task/:taskId',
        name: 'SlaManagementServiceRequestTask',
        props: true,
        component: () => import(/* webpackChunkName: "sla-task" */ '@/views/slaManagement/tasks/TaskDetailsPage'),
      },
    ],
  },

  /** ***************************************** */
  /*    ELECTRONIC AUTHORISATION MANAGEMENT    */
  /** *************************************** */
  {
    path: '/electronic-authorisations/',
    component: Main,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/electronic-authorisations/dashboard',
        name: 'ElectronicAuthorisationsDashboard',
        component: () => import(/* webpackChunkName: "electronic-authorisations" */ '@/views/electronicAuthorisations/Dashboard'),
      },

      // ////////////////////////
      // Work Authorisations Inbox
      {
        path: '/electronic-authorisations/work-authorisations',
        name: 'ElectronicAuthorisationsWorkAuthorisationsInbox',
        component: () => import(/* webpackChunkName: "electronic-authorisations-work-authorisations" */ '@/views/electronicAuthorisations/inbox/WorkAuthorisationInbox'),
      },

      // ////////////////////////
      // Work Authorisation Page
      {
        path: '/electronic-authorisations/work-authorisations/:serviceRequestNumber',
        name: 'ElectronicAuthorisationsViewWorkAuthorisation',
        props: true,
        component: () => import(/* webpackChunkName: "electronic-authorisations" */ '@/views/electronicAuthorisations/workAuthorisations/WorkAuthorisationPage'),
      },

      // ////////////////////////
      // Create Work Authorisation
      {
        path: '/electronic-authorisations/create-work-authorisation',
        name: 'ElectronicAuthorisationsCreateWorkAuthorisation',
        props: true,
        component: () => import(/* webpackChunkName: "electronic-authorisations" */ '@/views/electronicAuthorisations/workAuthorisations/WorkAuthorisationPage'),
      },

      // ////////////////////////
      // Remittance Advice
      {
        path: '/electronic-authorisations/remittance-advice',
        name: 'ElectronicAuthorisationsRemittanceAdvice',
        component: () => import(/* webpackChunkName: "electronic-authorisations-remittance-advice" */ '@/views/electronicAuthorisations/remittance/RemittanceAdvice'),
      },
    ],
  },

  /** ***************************************** */
  /*          FUND MANAGEMENT                  */
  /** *************************************** */
  {
    path: '/fund-management/',
    component: Main,
    meta: {
      requiresAuth: true,
    },
    children: [
      // ////////////////////////
      // Commission Charges Page
      {
        path: '/fund-management/commission-charges',
        name: 'FundManagementViewCommissionCharges',
        props: true,
        component: () => import('@/views/fundManagement/commissionCharges/CommissionCharges'),
      },
    ],
  },

  /** ***************************************** */
  /*          FUND ALLOCATIONS                 */
  /** *************************************** */
  {
    path: '/fund-allocations/',
    component: Main,
    meta: {
      requiresAuth: true,
    },
    children: [
      // ////////////////////////
      // Create Fund Allocations Request
      {
        path: '/fund-allocations/create-fund-allocation-request',
        name: 'CreateFundAllocationsRequest',
        props: true,
        component: () => import('@/views/fundAllocations/fundAllocations/FundAllocationPage'),
      },

      // ////////////////////////
      // Fund Allocations Inbox
      {
        path: '/fund-allocations/fund-allocations-inbox',
        name: 'FundAllocationsInbox',
        props: true,
        component: () => import('@/views/fundAllocations/inbox/FundAllocationsInbox'),
      },
      // Fund Allocation
      {
        path: '/fund-allocations/fund-allocation/:fundAllocationRequestNumber',
        name: 'FundAllocationsViewFundAllocation',
        props: true,
        component: () => import('@/views/fundAllocations/fundAllocations/FundAllocationPage'),
      },
    ],
  },

  /** ***************************************** */
  /*            VEHICLE CAPTURING              */
  /** *************************************** */
  {
    path: '/vehicle-capturing/',
    component: Main,
    meta: {
      requiresAuth: true,
    },
    children: [
      // ////////////////////////
      // Create Vehicle Upload Request
      {
        path: '/vehicle-capturing/create-vehicle-request',
        name: 'CreateVehiclesRequest',
        props: true,
        component: () => import('@/views/vehicleCapturing/CreateVehiclesRequest'),
      },

      // ////////////////////////
      // View Vehicle Capture Request
      {
        path: '/vehicle-capturing/vehicle-capture/:vehicleCaptureRequestNumber',
        name: 'VehicleCaptureViewVehicleCapture',
        props: true,
        component: () => import('@/views/vehicleCapturing/vehicleCaptures/VehicleCaptureDetails'),
      },

      // ////////////////////////
      // Vehicle Upload Inbox
      {
        path: '/vehicle-capturing/vehicle-capturing-inbox',
        name: 'VehicleCapturingInbox',
        props: true,
        component: () => import('@/views/vehicleCapturing/inbox/VehicleCaptureInbox'),
      },
    ],
  },

  /** *************** */
  /*    GENERAL      */
  /** ************* */
  {
    path: '/',
    component: Blank,
    children: [
      {
        path: '/staff/login',
        name: 'StaffLogin',
        component: Login,
        meta: {
          userType: 'staff',
        },
      },
      {
        path: '/merchant/login',
        name: 'MerchantLogin',
        component: Login,
        meta: {
          userType: 'merchant',
        },
      },
      {
        path: '/customer/login',
        name: 'CustomerLogin',
        component: Login,
        meta: {
          userType: 'customer',
        },
      },
      {
        path: '/authenticate',
        name: 'Authenticate',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/session/Authenticate'),
      },
      {
        path: '/logs',
        name: 'Logs',
        component: () => import(/* webpackChunkName: "logs" */ '@/views/error/LogViewer'),
      },
      {
        path: '*',
        name: '404',
        component: () => import(/* webpackChunkName: "404" */ '@/views/error/404'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const accessToken = await getAccessToken();
    const userType = localStorage.getItem('userType') || 'merchant';

    if (accessToken && !isJwtExpired(accessToken)) {
      // Get logged in user's groups
      const groups = await getUserGroups();

      // Get the current route's configured allowed roles
      let roles = [];
      for (let i = 0; i < menuItems.length; i += 1) {
        if (menuItems[i].path === to.path) {
          roles = menuItems[i].roles;
          break;
        }

        if (menuItems[i].children) {
          for (let j = 0; j < menuItems[i].children.length; j += 1) {
            if (menuItems[i].children[j].path === to.path) {
              roles = menuItems[i].children[j].roles;
              break;
            }
          }
        }
      }

      // Match to see whether the user has access
      if (roles.length === 0) {
        // If no roles are found, then assume it is a utility page
        // ex, /404, /logs, /login, etc
        next();
      } else if (roles[0] === 'ALL') {
        next();
      } else if (roles.filter(x => groups.includes(x)).length > 0) {
        // User has access
        next();
      } else {
        // If not, warn the user and send them back home
        notify.warning('You dont have access to this resource');

        next({
          path: '/',
        });
      }
    } else {
      notify.warning('Your Session has expired, please login');

      store.commit('userSettings/redirectUrl', to.fullPath);

      next({
        name: LOGIN_NAMES[userType],
      });
    }
  } else {
    next();
  }
});

export default router;
