export default {
  clientResults(currentState, value) {
    const state = currentState;
    state.clientResults = Object.assign([], value);
  },
  vehicleResults(currentState, value) {
    const state = currentState;
    state.vehicleResults = Object.assign([], value);
  },

};
