export default {
  clientResults: [],
  billingCentreResults: [],
  vehicleResults: [],
  transactionCodeResults: [],
  cardTypesResults: [],
  adminFeesResults: [],
  authorityGroupsResults: [],
  clientAddressResults: [],
  merchantsTypeCodeResults: [],
  productCodesResults: [],
};
