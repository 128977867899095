export default {
  fundAllocations(currentState, value) {
    const state = currentState;
    state.fundAllocations = value;
  },
  fundAllocation(currentState, value) {
    const state = currentState;
    state.fundAllocation = value;
  },
};
