import _cloneDeep from 'lodash/cloneDeep';

export default {
  serviceRequest(currentState, value) {
    const state = currentState;
    state.serviceRequest = _cloneDeep(value);
  },

  serviceRequests(currentState, value) {
    const state = currentState;
    state.serviceRequests = value;
  },

  serviceRequestTask(currentState, value) {
    const state = currentState;
    state.serviceRequestTask = { ...value };
  },

  serviceRequestTasks(currentState, value) {
    const state = currentState;
    state.serviceRequestTasks = value;
  },

  showServiceRequestTasks(currentState, value) {
    const state = currentState;
    state.showServiceRequestTasks = value;
  },

};
