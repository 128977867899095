import notify from '@/utils/notify';
import rest from '@/plugins/rest';

export default {

  async getAdminFees({ commit }, clientId) {
    try {
      const { data } = await rest.get(`/clients/${clientId}/admin-fees`);
      commit('adminFeesResults', data);

      return data;
    } catch (error) {
      notify.error('An error occured while loading the admin fees Details.');

      const errorHandler = new ErrorEvent();
      errorHandler.sendError(error, 'Get admin fees Details');

      return Promise.resolve();
    }
  },
};
